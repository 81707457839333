<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item :to="{ name: 'DashboardHome' }"
              ><i class="fa fa-home"></i
            ></b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'MainGroupbuyingsList' }"
              >總公司團購管理
</b-breadcrumb-item
            >
            <b-breadcrumb-item active>總公司庫存品開團</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row mb-2 align-items-end">
        <div class="col-8 d-flex items-center">
          <h4 class="font-weight-bold">總公司庫存品開團</h4>
        </div>

        <router-link
          v-if="checkPermission([consts.STORE_GROUPBUYING_DRAFT]) && step == STEP.SCAN_BARCODE"
          class="btn btn-outline-warning"
          :to="{ name: 'MainGroupbuyingDraft' }"
        >
          編輯草稿
        </router-link>
      </div>
      <template v-if="step === STEP.SCAN_BARCODE">
      <!-- 不需要這個元件 -->
        <b-form-group
          v-if="false"
          label-cols="12"
          label-cols-lg="2"
          label-size="sm"
          label="選擇分店"
          label-for="input-1"
        >
          <div>
            <b-spinner
              small
              v-if="isFetchFirstLayerBranches"
              variant="secondary"
            ></b-spinner>

            <b-form-select
              v-else
              class="form-control form-control-sm col-12 col-xl-6"
              v-model="firstBranch"
              :state="v$.form.branch_id.$error ? false : null"
              @change="handleFirstBranch"
            >
              <b-form-select-option :value="null" disabled>
                請選擇分店
              </b-form-select-option>
              <b-form-select-option
                v-for="branch in mapFirstLayerBranches"
                :key="branch.id"
                :value="branch"
              >
                {{ branch.name }}
              </b-form-select-option>
            </b-form-select>
          </div>
          <div>
            <b-spinner
              small
              v-if="isFetchSecondLayerBranches"
              variant="secondary"
            ></b-spinner>

            <b-form-select
              v-if="mapSecondLayerBranches && mapSecondLayerBranches.length > 1"
              class="form-control form-control-sm col-12 col-xl-6 mt-2"
              v-model="secondBranch"
              :state="v$.form.branch_id.$error ? false : null"
              @change="handleSecondBranch"
            >
              <b-form-select-option :value="null" disabled>
                請選擇分店
              </b-form-select-option>
              <b-form-select-option
                v-for="branch in mapSecondLayerBranches"
                :key="branch.id"
                :value="branch"
              >
                {{ branch.name }}
              </b-form-select-option>
            </b-form-select>
          </div>

          <div>
            <b-spinner
              small
              v-if="isFetchThirdLayerBranches"
              variant="secondary"
            ></b-spinner>

            <b-form-select
              v-if="mapThirdLayerBranches && mapThirdLayerBranches.length > 1"
              class="form-control form-control-sm col-12 col-xl-6 mt-2"
              v-model="thirdBranch"
              :state="v$.form.branch_id.$error ? false : null"
              @change="handleThirdBranch"
            >
              <b-form-select-option :value="null" disabled>
                請選擇分店
              </b-form-select-option>
              <b-form-select-option
                v-for="branch in mapThirdLayerBranches"
                :key="branch.id"
                :value="branch"
              >
                {{ branch.name }}
              </b-form-select-option>
            </b-form-select>
          </div>

          <b-form-invalid-feedback :state="!v$.form.branch_id.$error">
            此欄位為必填
          </b-form-invalid-feedback>
        </b-form-group>

        <div class="s-barcode-scanner-wrapper">
          <BarcodeScanner class="mb-4" @input="barcode = $event" />

          <div
            v-if="!canBarcodeFindProduct && barcode"
            class="d-flex justify-content-center"
          >
            查無商品
          </div>

          <b-form-input
            class="mt-4 mb-5"
            placeholder="手動輸入"
            v-model="barcode"
            @keyup.enter="fetchProductInfo"
          />
          <div class="d-flex justify-content-center">
            <b-button class="mr-3" variant="outline-danger">取消</b-button>
            <b-button
              variant="success"
              :disabled="!barcode"
              @click="fetchProductInfo"
              >確認</b-button
            >
          </div>
        </div>
      </template>
      <template v-if="step === STEP.SHOW_PRODUCT_INFO">
        <!-- <p class="mb-5">Barcode: {{ barcode }}</p> -->
        <div class="mb-4 product-info">
          <div>國碼查詢結果：</div>
          <div>品名: {{ product.name }}</div>
          <div>店內碼: {{ product.barcode }}</div>
          <div>國際碼: {{ product.ian }}</div>
          <div>原價: {{ product.orig_price }}</div>
          <div>售價: {{ product.sale_price }}</div>
        </div>

        <div style="color:#e06666;padding:20px 0px;font-size:14px;">* 分店實際開團價格，將依各分店店號實際查詢結果為準</div>

        <div class="d-flex justify-content-center buttons__action">
          <b-button class="mr-3" variant="outline-danger" @click="rescan"
            >重新掃描</b-button
          >
          <b-button class="mr-3" variant="success" @click="next" :disabled="!canClick"
            >掃描<br class="d-lg-none"/>其他商品</b-button
          >
          <b-button variant="success" @click="finish" :disabled="!canClick">編輯<br class="d-lg-none"/>團購設定</b-button>
        </div>
      </template>
    </b-card>
  </div>
</template>

<script>

import PermissionChecker from '@/utils/PermissionChecker'
import * as consts from '@/consts'
import BarcodeScanner from "@/components/BarcodeScanner/Index";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { mapGetters } from "vuex";
import branchApi from "@/apis/branch";
import groupbuyApi from "@/apis/groupbuy";

const STEP = {
  SCAN_BARCODE: 1,
  SHOW_PRODUCT_INFO: 2,
};

export default {
  components: { BarcodeScanner },
  setup: () => ({ v$: useVuelidate({ $lazy: true }) }),
  data: () => ({
    canBarcodeFindProduct: true,
    canClick: true,
    step: STEP.SCAN_BARCODE,
    barcode: null,
    product: {
      id: null,
      name: null,
      barcode: null, // 店內碼
      ian: null, // 國際碼
      rating: null, // 批價
      bargain_price: null, // 特價
      unit_price: null, // 單價
      discount: null, // 組合優惠
    },
    form: {
      branch_id: null,
    },
    firstBranch: null,
    secondBranch: null,
    thirdBranch: null,
    firstLayerBranches: [],
    secondLayerBranches: [],
    thirdLayerBranches: [],
    isFetchFirstLayerBranches: false,
    isFetchSecondLayerBranches: false,
    isFetchThirdLayerBranches: false,
    consts
  }),
  computed: {
    STEP: () => STEP,
    ...mapGetters("general", ["currentBranch", "organization"]),
    mapFirstLayerBranches() {
      return this.mapBranches(this.firstLayerBranches);
    },
    mapSecondLayerBranches() {
      return this.mapBranches(this.secondLayerBranches);
    },
    mapThirdLayerBranches() {
      return this.mapBranches(this.thirdLayerBranches);
    },
  },
  watch: {
    barcode: function (val) {
      if (!val) {
        return;
      }

      this.handleBarcodeChange();
    },
  },
  created: function () {
    this.init();
  },
  methods: {
    async init() {
      await this.getFirstLayerBranches();
      if (this.firstLayerBranches.length == 1) {
        this.form.branch_id = this.firstLayerBranches[0].id;
      } else if (this.firstLayerBranches.length > 1) {
        for(let i = 0, max = this.firstLayerBranches.length; i < max; i++) {
          let br = this.firstLayerBranches[i];
          if (br.name == '全部分店') {
            continue;
          }

          if (this.form.branch_id) {
            break;
          }
          this.form.branch_id = br.id;
        }
      }
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker;
      return checker.check(permissions);
    },
    async handleBarcodeChange() {
      try {
        const { data } = await groupbuyApi.getMainStoreGroupBuyProduct(
          this.barcode,
        );

        this.canBarcodeFindProduct = true;

        if (data.stop_sell == 'Y') {
          this.$swal.fire({
            title: "錯誤",
            type: "error",
            text: "商品停止販售，請掃描其他商品",
          });
          this.barcode = null;
          return;
        }

        this.product.id = data.id;
        this.product.name = data.name;
        this.product.barcode = data.poya_prod;
        this.product.ian = data.prod;
        this.product.orig_price = data.orig_price;
        this.product.discount = data.combi_type;

        if (data.mark == 'Y' && parseInt(data.combi_qty) > 1) {
          if (data.combi_type == '' || data.combi_type == null) {
            this.canClick = false;
            this.$swal.fire({
              title: "錯誤",
              type: "error",
              text: "商品價格資訊異常，請掃描其他商品",
            });
          } else {
            this.product.sale_price = data.combi_type;
          }
        } else {
          this.product.sale_price = data.sale_price;
        }

        this.step = STEP.SHOW_PRODUCT_INFO;
      } catch (err) {
        this.canBarcodeFindProduct = false;
      }
    },

    async fetchProductInfo() {
      try {
        const { data } = await groupbuyApi.getMainStoreGroupBuyProduct(
          this.barcode,
        );

        this.product.id = data.id;
        this.product.name = data.name;
        this.product.barcode = data.poya_prod;
        this.product.ian = data.prod;
        this.product.orig_price = data.orig_price;
        this.product.discount = data.combi_type;

        if (data.mark == 'Y' && parseInt(data.combi_qty) > 1) {
          if (data.combi_type == '' || data.combi_type == null) {
            this.canClick = false;
            this.$swal.fire({
              title: "錯誤",
              type: "error",
              text: "商品價格資訊異常，請掃描其他商品",
            });
          } else {
            this.product.sale_price = data.combi_type;
          }
        } else {
          this.product.sale_price = data.sale_price;
        }

        this.step = STEP.SHOW_PRODUCT_INFO;
      } catch (err) {
        console.log("err", err);
        this.$swal.fire({
          title: "錯誤",
          type: "error",
          text: "查無商品資料",
        });
      }
    },
    rescan() {
      this.canBarcodeFindProduct = true;
      this.barcode = null;
      this.step = STEP.SCAN_BARCODE;
      this.canClick = true;
    },
    async finish() {
      try {
        const data = await groupbuyApi.syncMainStoreGroupBuyProducts({
          sku_serial_number: this.barcode,
        });

        this.barcode = null;
        this.$router.push({
          name: "MainGroupbuyingUpdateImage",
          params: {
            id: data.data.id,
          },
        });
      } catch (err) {
        console.error(err);
        this.$swal.fire({
          title: "錯誤",
          type: "error",
          text: "建立商品失敗",
        });
      }
    },
    async next() {
      try {
        await groupbuyApi.syncMainStoreGroupBuyProducts({
          sku_serial_number: this.barcode,
        });
      } catch (err) {
        this.$swal.fire({
          title: "錯誤",
          type: "error",
          text: "建立商品失敗",
        });
        return;
      }

      this.barcode = null;
      this.step = STEP.SCAN_BARCODE;
    },
    mapBranches(branches) {
      return branches
        .sort((a, b) => a.level - b.level)
        .sort((a, b) => {
          // if "level" is the same, order by "order"
          if (a.level === b.level) {
            return a.order - b.order;
          }
        })
        .map((branch) => {
          let branchName = `${branch.branch_code} ${branch.name}`;
          if (branch.level === 2) {
            branchName = `- ${branch.branch_code} ${branch.name}`;
          }
          if (branch.level === 3) {
            branchName = `- - ${branch.branch_code} ${branch.name}`;
          }

          return {
            ...branch,
            value: branch.id,
            name: branchName,
          };
        });
    },
    async getFirstLayerBranches() {
      this.isFetchFirstLayerBranches = true;
      const branches = await this.getBelowBranches(this.currentBranch.id);
      this.firstLayerBranches = branches;
      if (branches.length > 1) {
        this.firstLayerBranches = branches.filter(
          (branch) => branch.id !== this.currentBranch.id
        );
        this.form.branch_id = null;
      } else {
        this.firstBranch = this.mapFirstLayerBranches[0];
        this.form.branch_id = this.currentBranch.id;
      }

      this.isFetchFirstLayerBranches = false;
    },
    async getBelowBranches(branch_id) {
      try {
        const { data } = await branchApi.getCurrentBelowBranches({
          branch_id,
        });

        return data.data.filter(
          (branch) => branch.org_id === this.organization.id
        );
      } catch (error) {
        this.$swal("失敗", error.response.data.message, "error");
        return;
      }
    },
    async handleFirstBranch(branch) {
      this.isFetchSecondLayerBranches = true;
      const branches = await this.getBelowBranches(branch.id);
      if (branches.length > 1) {
        this.secondLayerBranches = branches.filter(
          (branch) => branch.id !== this.firstBranch.id
        );
        this.form.branch_id = null;
      } else {
        this.secondLayerBranches = [];
        this.form.branch_id = branch.id;
      }
      this.isFetchSecondLayerBranches = false;
    },
    async handleSecondBranch(branch) {
      this.isFetchThirdLayerBranches = true;
      const branches = await this.getBelowBranches(branch.id);
      this.thirdLayerBranches = branches;
      if (branches.length > 1) {
        this.thirdLayerBranches = branches.filter(
          (branch) => branch.id !== this.secondBranch.id
        );
        this.form.branch_id = null;
      } else {
        this.form.branch_id = branch.id;
      }
      this.isFetchThirdLayerBranches = false;
    },
  },

  validations() {
    return {
      form: {
        branch_id: { required },
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.s-barcode-scanner-wrapper {
  max-width: 480px;
  margin: auto;
}

.product-info {
  line-height: 2;
}

@media screen and (max-width: 768px) {
  .buttons__action > button {
    flex: 1;
    line-height: 1.2;
  }
}
</style>
